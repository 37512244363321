<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="reportage.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Özet Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="reportage.summary"
          placeholder="Özet Metin"
        />
      </b-form-group>
      <b-form-group
        label="Röportaj Metni"
        label-for="content"
      >
        <quill-editor
          id="content"
          v-model="reportage.content"
        />
      </b-form-group>
      <b-form-group
        label="Ay"
        label-for="month"
      >
        <v-select
          id="month"
          v-model="reportage.month"
          placeholder="Ay"
          :options="months"
          label="title"
          :reduce="month => month.id"
        />
      </b-form-group>
      <b-form-group
        label="Yıl"
        label-for="year"
      >
        <cleave
          id="year"
          v-model="reportage.year"
          class="form-control"
          :raw="false"
          :options="options.year"
          placeholder="Yıl"
        />
      </b-form-group>
    </b-card>
    <b-card title="Görsel">
      <b-form-group label="Görsel Yükle">
        <b-form-file
          v-model="reportage.upload_image"
          placeholder="Bir görsel seçin veya buraya bırakın..."
          drop-placeholder="Görseli buraya bırakın..."
          browse-text="Görsel Seçin"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BButton,
    quillEditor,
    vSelect,
    Cleave,
  },
  data() {
    return {
      options: {
        year: {
          blocks: [4],
          numericOnly: true,
        },
      },
      months: [
        {
          id: '1',
          title: 'Ocak',
        }, {
          id: '2',
          title: 'Şubat',
        }, {
          id: '3',
          title: 'Mart',
        }, {
          id: '4',
          title: 'Nisan',
        }, {
          id: '5',
          title: 'Mayıs',
        }, {
          id: '6',
          title: 'Haziran',
        }, {
          id: '7',
          title: 'Temmuz',
        }, {
          id: '8',
          title: 'Ağustos',
        }, {
          id: '9',
          title: 'Eylül',
        }, {
          id: '10',
          title: 'Ekim',
        }, {
          id: '11',
          title: 'Kasım',
        }, {
          id: '12',
          title: 'Aralık',
        },
      ],
      submitStatus: false,
    }
  },
  computed: {
    reportage() {
      return this.$store.getters['reportage/getReportage']
    },
    saveStatus() {
      return this.$store.getters['reportage/getReportageSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('reportage/reportageReset')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.reportage.submitStatus = true
      this.$store.dispatch('reportage/reportageSave', this.reportage)
    },
  },
}
</script>
